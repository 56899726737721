<template>
    <div class="container">
<!--        <modal name="example">This is an example</modal>-->

        <div class="row">
            <div class="col-8 d-flex justify-content-start">
                <strong v-if="plans.PlanListing">Ad Verifier: {{ plans.PlanListing.ProxyType }}</strong>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <strong v-if="plans.PlanListing">ID #: {{ plans.PlanListing._id }}</strong>
            </div>
        </div>

        <div class="mt-2 row" >
            <div class="col-8 d-flex justify-content-start">
                <b-button class="btn mr-2 text-white font-weight-bold" size="sm"
                          :to="{ path: '/clients', query: { edit: '1',id:plans.ClientId } }">Client Details
                </b-button>
            <b-button class="btn  btn-warning mr-2 text-white " size="sm"
                      :to="{ path: '/product/management', query: { client_id: plans.ClientId } }"><strong>Product Management
            </strong></b-button>
<!--                <b-button class="btn  btn-warning text-white mr-2" size="sm"-->
<!--                          :to="{ path: '/plan/listing', query: { client_id:plans.ClientId } }"><strong>Client Report-->
<!--                </strong></b-button>-->
            </div>

            <div class="col-4 d-flex justify-content-end">
            </div>
        </div>
        <div class="row mb-2">
            <hr class="hr-header" data-content="Product Settings" />
        </div>
        <div class="row d-flex justify-content-center align-content-center">
<!--            Right Section-->
            <div class="col-8">
                <b-card no-body>
                    <b-tabs card>
                        <b-tab :title="'Domain ' + (idx + 1)" v-for="(dom, idx) in domains" :key="dom._id">
                            <div class="row">
                                <div class="col-6 col-md-6 pr-4">
                                    <div class="row">
                                        <div class="col-6">
                                            <p><strong>Domain {{ idx + 1}}</strong> of {{ plans.PlanListing ? plans.PlanListing.NumberOfDomains : 'NAN' }}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <b-input-group>
<!--                                                <b-form-input placeholder="Enter Domain Here" autocomplete="off" v-model="dom.domain" @change="validateDomain(dom.domain)" style="background-color: #efefef;"></b-form-input>-->
<!--                                                <b-input-group-append>-->
<!--                                                    <b-button :disabled="domains.length === 1" class="" @click="deleteDomain(idx)" variant="dark">Delete</b-button>-->
<!--                                                </b-input-group-append>-->

                                                <b-form-select v-model="dom.domain_id" class="form-control">
                                                    <b-form-select-option value="" selected>-- Select Domain --</b-form-select-option>
                                                    <option v-for="domain in domainsNM" :key="domain._id" :value="domain._id">{{ domain.name }}</option>
                                                </b-form-select>
                                            </b-input-group>

                                        </div>

                                        <div v-if="dom.status === 'active'" class="col-12 mt-2">
                                            <b-button class="btn  btn-info text-white " size="sm"
                                                      :to="{ path: '/geoCampaignManagement', query: { client_id:dom._id } }"><strong>Campaign Report
                                            </strong></b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 pr-4">
                                    <div style="padding-top: 10px" class="row">
                                        <div class="col-12">
                                            <div style="margin-left: 3px;" class="row">
                                                <div style="margin-right: 20px;">I need custom tracking for google analytics</div>
                                                <b-form-checkbox
                                                    id="checkbox-1"
                                                    v-model="dom.googleanalytics.isrequired"
                                                    name="checkbox-1"
                                                    switch
                                                    :value="true"
                                                    :unchecked-value="false"/>
                                            </div>
                                        </div>
                                        <div style="margin-bottom: 10px;" v-if="dom.googleanalytics.isrequired" class="col-6">
                                                <div>
                                                    <label>Google Analytics Source</label>
                                                    <b-form-input  v-model="dom.googleanalytics.source" type="text"/>
                                                </div>
                                        </div>
                                        <!--<div class="col-6">
                                                <div>
                                                    <label>Google Analytics Unique Id</label>
                                                    <b-form-input v-if="dom.googleanalytics.unqiue" v-model="dom.googleanalytics.unqiue" type="text"/>
                                                </div>
                                        </div>-->
                                    </div>
                                </div>
                                <div class="col-6 pr-4 pl-5" v-if="plans.PlanListing.Driven === 'click'">
                                    <div class="row d-flex align-content-center justify-content-center"><strong>Amount of Clicks</strong></div>
                                    <div class="row mt-3 d-flex align-content-center justify-content-center">
                                        <div class="col-8 col-md-8 d-flex align-content-center justify-content-center" style="background-color: #f7f9fa; border-radius: 10px;">
                                            <b-input class="editInput" autocomplete="off" type="number" v-if="edit === 'numberClicks'" v-model="dom.NumberOfClicks" @blur="edit=false; $emit('update')" @keyup.enter="edit=false; $emit('update')" v-focus></b-input>
                                            <p style="color: red; font-size: 25px; text-align: center;" v-else @click="edit='numberClicks'"><strong>{{ dom.NumberOfClicks|convert }}</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-6 pr-5 pl-4">
                                    <div class="row d-flex align-content-center justify-content-center" style="background-color: #367bf5; color: white;"><strong><strong>Start Time</strong></strong></div>
                                    <div class="row mt-3 d-flex align-content-center justify-content-center">
                                        <div class="col-6 col-md-6" style="background-color: #f7f9fa; border-radius: 10px;">
                                            <b-input class="p-0 editInput" placeholder="0:00" autocomplete="off" v-model="dom.StartTime" @change="validateTimeHrMinStart(dom.StartTime)" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 pr-4 pl-5">
                                    <div class="row d-flex align-content-center justify-content-center" style="background-color: #367bf5; color: white;"><strong>Stop Time</strong></div>
                                    <div class="row mt-3 d-flex align-content-center justify-content-center">
                                        <div class="col-6 col-md-6" style="background-color: #f7f9fa; border-radius: 10px;">
                                            <b-input class="p-0 editInput" placeholder="0:00" autocomplete="off" v-model="dom.StopTime" @change="validateTimeHrMinStop(dom.StopTime)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-6 pr-5 pl-4">
                                    <div class="row d-flex align-content-center justify-content-center" style="background-color: #367bf5; color: white;"><strong>Start Date</strong></div>
                                    <div class="row mt-3 d-flex align-content-center justify-content-center">
                                        <div class="col-6 col-md-6" style="background-color: #f7f9fa; border-radius: 10px;">
                                            <b-input-group style="width: 200px">
                                                <b-form-input class="editInput" autocomplete="off" v-model="dom.StartDate" placeholder="_-_-_" @change="validateDate(dom.StartDate)"></b-form-input>
                                                <b-input-group-append>
                                                    <b-form-datepicker class="editInput" locale="en-US" v-model="dom.StartDate" button-only id="startDate" size="sm" :min="min" ></b-form-datepicker>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 pr-4 pl-5">
                                    <div class="row d-flex align-content-center justify-content-center" style="background-color: #367bf5; color: white;"><strong>End Date</strong></div>
                                    <div class="row mt-3 d-flex align-content-center justify-content-center">
                                        <div class="col-6 col-md-6" style="background-color: #f7f9fa; border-radius: 10px;">
                                            <b-input-group style="width: 200px">
                                                <b-form-input class="editInput" autocomplete="off" v-model="dom.StopDate" placeholder="_-_-_" @change="validateDate(dom.StopDate)"></b-form-input>
                                                <b-input-group-append>
                                                    <b-form-datepicker class="editInput" locale="en-US" v-model="dom.StopDate" button-only id="stopDate" size="sm" :min="min" ></b-form-datepicker>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-6 pr-5 pl-4">
                                    <div class="row mt-3 mb-3 d-flex justify-content-center">
                                        <b-button v-if="dom._id" variant="primary" size="sm" @click="tos = true">Configure TOS</b-button>
                                    </div>
                                </div>


                                <div class="col-6 pr-5 pl-4" >
                                    <div class="row mt-3 mb-3 d-flex justify-content-center">
                                        <b-button v-if="dom._id" variant="dark" size="sm" @click="calendar(dom)">View Calendar</b-button>
                                    </div>
                                </div>
                                <t-o-s v-if="tos" :linklvlTOS="dom.linkLevelTos" :domain_config_id="dom._id" v-on:close="tos = false" v-on:modified="tos = false; tosNotify()"></t-o-s>
                            </div>
                            <hr v-if="dom._id" />
<!--                            <div class="row d-flex justify-content-center align-content-center">-->
<!--                                <div class="col-12">-->
<!--                                    <strong>Residential city</strong>-->
<!--                                </div>-->
<!--                                <div class="row">-->
<!--                                    <div class="col-12">-->
<!--                                        <b-form inline>-->
<!--                                            <label class="mr-sm-2"><strong>Residential City Percentage:</strong></label>-->
<!--                                            <b-form-input id="rcityp" style="background-color: #efefef;" type="text" placeholder="Enter Percentage" />-->
<!--                                        </b-form>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="row d-flex justify-content-center " v-if="dom && dom.status !== 'active'">
                                <div class="col-12 col-md-12 d-flex justify-content-center">
                                    <b-button v-if="!dom._id" class="" variant="success" @click="saveDomainConfig(idx)"><strong>Save Configuration</strong></b-button>
                                    <b-button v-if="dom._id" class="text-white" variant="warning" @click="updateDomainConfig(idx)"><strong>Update Configuration</strong></b-button>
                                </div>
                            </div>
                            <div id="Residentialcity" v-if="plans.PlanListing.ProxyType === 'ResidentialCity' && dom._id">
                                <div class="row mt-4">
                                    <div class="col-4 pl-4 pr-4">
                                        <select v-model="locate.country" @change="Getstates();" :disabled="dom.status === 'active'" class="form-control">
                                            <option value="" selected>-- Any country --</option>
                                            <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-4 pl-4 pr-4">
                                        <select v-model="locate.state" @change="Getcities();" :disabled="dom.status === 'active'" class="form-control">
                                            <option value="" selected>-- Any state --</option>
                                            <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="locate.state">
                                    <div class="col-11 col-md-11 pl-4 pr-4" >
                                        <ejs-multiselect id='multiselectcity' v-model="locate.city" :fields="cityFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='cities'></ejs-multiselect>
                                    </div>
                                    <div class="col-1 col-md-1 pl-0 d-flex justify-content-center" v-if="locate.city !== ''">
                                        <b-button size="sm" variant="success" @click="addGeos(idx, dom._id)" v-text="'Add'" />
                                    </div>
                                </div>
                            </div>
                            <div id="Residentialstate" v-if="plans.PlanListing.ProxyType === 'ResidentialState' && dom._id">
                                <div class="row mt-4">
                                    <div class="col-4 pl-4 pr-4">
                                        <select v-model="locate.country" @change="Getstates();" :disabled="dom.status === 'active'" class="form-control">
                                            <option value="" selected>-- Any country --</option>
                                            <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="locate.country">
                                    <div class="col-11 col-md-11 pl-4 pr-4" >
                                        <ejs-multiselect id='multiselect' v-model="locate.state" :fields="stateFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='states'></ejs-multiselect>
                                    </div>
                                    <div class="col-1 pl-0 d-flex justify-content-center" v-if="locate.state.length !== 0">
                                        <b-button size="sm" variant="success" @click="addGeos(idx, dom._id)" v-text="'Add'" />
                                    </div>
                                </div>
                            </div>
                            <div id="DataCenter" v-if="plans.PlanListing.ProxyType === 'Data Center' && dom._id">
                                <div class="row mt-4">
                                    <div class="col-11 col-md-11 pl-4 pr-4" >
                                        <ejs-multiselect id='multiselect' v-model="locate.country" :fields="dataFields" :allowFiltering="true" placeholder="Select Countries" :dataSource='countries'></ejs-multiselect>
                                    </div>

                                    <div class="col-1 col-md-1 pl-0 d-flex justify-content-center" v-if="locate.country.length !== 0">
                                        <b-button size="sm" variant="success" @click="addGeos(idx, dom._id)" v-text="'Add'" />
                                    </div>
                                </div>
                            </div>
                            <div id="Mobile" v-if="plans.PlanListing.ProxyType === 'Mobile' && dom._id">
                                <div class="row mt-4">
                                    <div class="col-4 pl-4 pr-4">
                                        <select v-model="locate.country" @change="Getstates();" :disabled="dom.status === 'active'" class="form-control">
                                            <option value="" selected>-- Any country --</option>
                                            <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-4 pl-4 pr-4">
                                        <select v-model="locate.state" @change="Getcities();" :disabled="dom.status === 'active'" class="form-control">
                                            <option value="" selected>-- Any state --</option>
                                            <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-4" v-if="locate.state">
                                    <div class="col-11 col-md-11 pl-4 pr-4" >
                                        <ejs-multiselect id='multiselect' v-model="locate.city" :fields="mobileFields" :allowFiltering="true" placeholder="Select Cities" :dataSource='cities'></ejs-multiselect>
                                    </div>
                                    <div class="col-1 col-md-1 pl-0 d-flex justify-content-center">
                                        <b-button size="sm" variant="success" @click="addGeos(idx, dom._id)" v-text="'Add'" />
                                    </div>
                                </div>
                                <hr>
                                <b-table
                                    striped
                                    :fields="fields"
                                    ref="selectedRow"
                                    :items="dom.geos">

                                    <template v-slot:cell(city)="row">
                                        <citySelect :domid="dom._id" :id="row.item._id" :value="row.item.city" :index="row.index" :planIdx="idx" :country="row.item.country" disabled="true" v-on:send-city="getcity" :state="row.item.state"></citySelect>
                                    </template>

                                    <template v-slot:cell(Actions)="row" >
                                        <b-button @click="removeGeos(row.index, idx)" :disabled="dom.status === 'active'" v-b-tooltip.hover title="Delete Geo" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                    </template>

                                </b-table>
                            </div>
                            <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType === 'ResidentialCity' && dom._id">
                                <b-table
                                    striped
                                    :fields="fields"
                                    ref="selectedRow"
                                    :items="dom.geos">

                                    <template v-slot:cell(city)="row">
                                        <citySelect :value="row.item.city" :disable="dom.status === 'active'" :domid="dom._id" :id="row.item._id" :index="row.index" :planIdx="idx" :country="row.item.country" disabled="true" v-on:send-city="getcity" :state="row.item.state"></citySelect>
                                    </template>

                                    <template v-slot:cell(Actions)="row">
                                        <b-button @click="removeGeos(row.index, idx, dom._id, row.item._id)" :disabled="dom.status === 'active'" v-b-tooltip.hover title="Delete Geo" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                    </template>

                                </b-table>
                            </div>
                            <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType === 'ResidentialState' && dom._id">
                                 <b-table
                                    striped
                                    :fields="RSfields"
                                    ref="selectedRow"
                                    :items="dom.geos">

                                    <template v-slot:cell(state)="row">
                                        <stateSelect :domid="dom._id" :id="row.item._id" :disable="dom.status === 'active'" :index="row.index" :planIdx="idx" :country="row.item.country" v-on:send-state="getstate" :state="row.item.state"></stateSelect>
                                    </template>

                                    <template v-slot:cell(Actions)="row" >
                                        <b-button @click="removeGeos(row.index, idx, dom._id, row.item._id)" v-b-tooltip.hover title="Delete Geo" :disabled="dom.status === 'active'" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                    </template>

                                </b-table>
                            </div>
                            <div class="row pr-3 pl-3 mt-2" v-if="plans.PlanListing.ProxyType === 'Data Center' && dom._id">
                                <b-table
                                    striped
                                    :fields="datFields"
                                    ref="selectedRow"
                                    :items="dom.geos">
                                    <template v-slot:cell(country)="row">
                                        <countrySelect :domid="dom._id" :id="row.item._id" :disable="dom.status === 'active'" :value="row.item.country" :planIdx="idx" :index="row.index" disabled="true" v-on:send-country="getcountry"></countrySelect>
                                    </template>

                                    <template v-slot:cell(Actions)="row" >
                                        <b-button @click="removeGeos(row.index, idx, dom._id, row.item._id)" v-b-tooltip.hover title="Delete Geo" :disabled="dom.status === 'active'" variant="danger" size="sm"><span class="fa fa-trash"></span></b-button>
                                    </template>

                                </b-table>
                            </div>

                            <configure-geo v-if="configureGeo" :domain="dom" :domain_nm="domainsNM" :plan="plans" :noc="Number(dom.NumberOfClicks)" :geos="geoConfig" v-on:closegeos="configureGeo = false" />

                            <div class="row p-3 d-flex justify-content-center" v-if="dom.geos && dom.geos.length !== 0">
                                <b-button size="sm" @click="configGeo(dom._id)"  variant="info">Configure Geos</b-button>
                            </div>

                            <div class="row p-3 d-flex justify-content-center" v-if="dom.geos && dom.geos.length !== 0 && dom.status !== 'active'">
                                <div class="col-12 col-md-12 d-flex justify-content-center">
                                    <b-button class="" @click="generateCampaign(dom)"><strong>Generate Campaign</strong></b-button>
                                </div>
                            </div>
                            <b-alert v-if="dom.status === 'active'" variant="warning" show>Campaign for <strong>{{ dom.domain }}</strong> has been generated. No further editing permitted.</b-alert>
                        </b-tab>

                        <template #tabs-end>
                            <b-nav-item role="presentation" @click="incrementDomain" href="#"><b>+</b></b-nav-item>
                        </template>
                    </b-tabs>
                </b-card>
            </div>
<!--            End Right Section-->
        </div>

        <div class="row d-flex justify-content-center align-content-center">
            <div class="col-12">
                <b-button size="sm" class="" to="/clients" style="border-radius: 6px;" variant="dark"><strong>All Clients</strong></b-button>
            </div>
        </div>

        <div class="row ml-1 mt-4 mr-1">
            <div class="col-5 pl-0 d-flex justify-content-start">
                <p v-if="plans.Status"><strong>Status: </strong> {{ plans.Status.toUpperCase() }}</p>
            </div>
            <div class="col-3 pr-0 d-flex justify-content-end">
                <p><strong>Date Created: </strong> {{ plans.created_ts | date_format_with_time }}</p>
            </div>
            <div class="col-3 pr-0 d-flex justify-content-end">
                <p><strong>Date Modified: </strong> {{ plans.modified_ts | date_format_with_time }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import swal from "sweetalert"
    import citySelect from "../components/domain/citySelect";
    import stateSelect from "../components/domain/stateSelect";
    import countrySelect from "../components/domain/countrySelect";
    import ConfigureGeo from '@/components/domain/configuration/geos'
    import TOS from '@/components/domain/configuration/tos'
    import {mapMutations} from "vuex";
    // import MobileSelect from "../components/domain/mobileSelect"

    export default {
        name: "DomainConfig",

        components: { citySelect, stateSelect, countrySelect, ConfigureGeo, TOS },
        data() {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getDay())

            const minDate = new Date(today)
            minDate.setDate(minDate.getDay() + 10)

            return {
                plans: {},
                domains: [],
                domainsNM: [],
                stopDate: '',
                configureGeo: false,
                tos: false,
                geoConfig: [],
                domain: {
                    domain_id: '',
                    NumberOfClicks: '',
                    StartTime: '',
                    StopTime: '',
                    StartDate: '',
                    StopDate: '',
                    geos: [],
                },
                min: minDate,
                countries: [],
                states: [],
                cities: [],
                edit: false,
                click: false,
                tableEdit: false,
                tableindex: '',
                locate: {
                    country: '',
                    state: '',
                    city: '',
                },
                countryFields:{ text: 'country_name', value: 'country_name' },
                cityFields: { text: 'city_name', value: 'city_name' },
                stateFields: { text: 'city_name', value: 'state_name' },
                dataFields: { text: 'name', value: 'code' },
                mobileFields: { text: 'city_name', value: 'city_name' },
            }
        },
        filters: {
            convert: function(value) {
                if (value < 1000000) {
                    return value / 1000 + 'K'
                } else {
                    return value / 1000000 + 'M'
                }
            },
            convertG: function(value) {
                return value / 1000000000 + 'G'
            }
        },
        created() {
            // this.$modal.show('example')
            this.$root.preloader = false;
            this.loadcountries();
            this.loadPlans().finally(() => {
                this.getDomain().finally(() => {
                    if ( this.domains.length === 0 ) {
                        this.incrementDomain();
                    }
                    this.getClientDomains()
                })
            })
        },
        computed: {
            fontSize() {
                return 50 - (120/(12-this.domain.TimeOnSite.length))
            },
            fields() {
              return [
                  {
                      key: 'country',
                      label: 'Country',
                      sortable: false
                  },
                  {
                      key: 'state',
                      label: 'State',
                      sortable: false
                  },
                  {
                      key: 'city',
                      label: 'City',
                      sortable: false
                  },
                  {
                      key: 'Actions',
                      label: 'Actions',
                      sortable: false
                  }
              ]
            },
            RSfields() {
              return [
                  {
                      key: 'country',
                      label: 'Country',
                      sortable: false
                  },
                  {
                      key: 'state',
                      label: 'State',
                      sortable: false
                  },
                  {
                      key: 'Actions',
                      label: 'Actions',
                      sortable: false
                  }
              ]
            },
            datFields(){
                return [
                    {
                        key: 'country',
                        label: 'Country',
                        sortable: false
                    },
                    {
                        key: 'Actions',
                        label: 'Actions',
                        sortable: false
                    }
                ]
            }
        },
        methods: {
            ...mapMutations(['setDomain']),
            getcity(item, idx, planIdx) {
                this.domains[planIdx].geos.splice(idx, 1, item)
                this.tableindex = idx

                axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                    if ( resp.data ) {
                        this.$bvToast.toast('Geos updated successfully.', {
                            title: <strong>Information: Geos Updated</strong>,
                            variant: 'info',
                            solid: true
                        })
                    }
                })
            },
            getClientDomains() {
                axios.get(`${this.$root.serverUrl}/admin/domain/domain/verified/${this.domains[0].client_id}` ).then((resp) => {
                    this.domainsNM = resp.data.data || []
                })
            },
            calendar(domain) {
              this.setDomain(domain);
              this.$router.push('/click/schedule')
            },
            getMobilecity(item, idx, planIdx) {
                this.domains[planIdx].geos.splice(idx, 1, item)
                this.tableindex = idx
            },
            getstate(item, idx, planIdx) {
                this.domains[planIdx].geos.splice(idx, 1, item)
                this.tableindex = idx

                axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                    if ( resp.data ) {
                        this.$bvToast.toast('Geos updated successfully.', {
                            title: <strong>Information: Geos Updated</strong>,
                            variant: 'info',
                            solid: true
                        })
                    }
                })
            },
            getcountry(item, idx, planIdx) {
                this.domains[planIdx].geos.splice(idx, 1, item)
                this.tableindex = idx

                axios.post(`${this.$root.serverUrl}/admin/domain/geos`, item).then((resp) => {
                    if ( resp.data ) {
                        this.$bvToast.toast('Geos updated successfully.', {
                            title: <strong>Information: Geos Updated</strong>,
                            variant: 'info',
                            solid: true
                        })
                    }
                })
            },
            removeGeos(idx, index, dom, id) {
                this.domains[index].geos.splice(idx, 1)
                axios.delete(`${this.$root.serverUrl}/admin/domain/geos/${dom}/${id}`).then((resp) => {
                    if ( !resp.error ) {
                        this.$bvToast.toast('Geos updated successfully.', {
                            title: <strong>Information: Geos Updated</strong>,
                            variant: 'info',
                            solid: true
                        })
                    } else {
                        this.$bvToast.toast('An Error Occurred.', {
                            title: <strong>ERROR</strong>,
                            variant: 'warning',
                            solid: true
                        })
                    }
                })
            },
            addGeos(idx, id) {
                this.locate.domainId = id

                axios.put(`${this.$root.serverUrl}/admin/domain/geos`, this.locate).then((resp) => {
                    if ( resp.data && resp.data.error ) {
                        this.$bvToast.toast(resp.data.error.message, {
                            title: <strong>Information: Geos Not Updated</strong>,
                            variant: 'warning',
                            solid: true
                        })
                    } else {
                        location.reload()
                    }
                })
            },
            configGeo(id) {
                axios.get(`${this.$root.serverUrl}/admin/domain/click-distribution/${id}`).then((resp) => {
                    if ( resp.data && resp.data.error ) {
                        console.log(resp)
                    } else {
                        this.geoConfig = resp.data.data
                        this.configureGeo = true;
                    }
                })
            },
            validateDate(date) {
              // let reg = /(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/;
              let reg = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
                if (!reg.test(date)) {
                    this.$bvToast.toast('You entered an invalid Date. Format: YYYY-MM-DD', {
                        title: <strong>Warning: Time on Site</strong>,
                        variant: 'warning',
                        solid: true
                    })
                    return false
                } else {
                    return true
                }
            },
            validateTimeOnPage(time) {
                  let reg = /^([0-6]?[0-9]|2[0-3]):[0-5][0-9]$/;
                  if (!reg.test(time)) {
                      this.$bvToast.toast('You entered an invalid Time on Page. Format: MM:SS', {
                          title: <strong>Warning: Start Time</strong>,
                          variant: 'warning',
                          solid: true
                      })
                      return false
                  } else {
                      return true
                  }
            },
            validateTimeHrMinStart(time) {
              let reg = /^([0-6]?[0-9]|2[0-3]):[0-5][0-9]$/;
              if (!reg.test(time)) {
                  this.$bvToast.toast('You entered an invalid time. Format: HH:MM', {
                      title: <strong>Warning: Start Time</strong>,
                      variant: 'warning',
                      solid: true
                  })
                  return false
              } else {
                  return true
              }
            },
            validateTimeHrMinStop(time) {
              let reg = /^([0-6]?[0-9]|2[0-3]):[0-5][0-9]$/;
              if (!reg.test(time)) {
                  this.$bvToast.toast('You entered an invalid time. Format: HH:MM', {
                      title: <strong>Warning: Stop Time</strong>,
                      variant: 'warning',
                      solid: true
                  })
                  return false
              } else {
                  return true
              }
            },
            validateDomain(dom) {
                let reg = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

                if (!reg.test(dom)) {
                    this.$bvToast.toast('You entered an invalid domain. Format: example.com', {
                        title: <strong>Warning: Invalid Domain</strong>,
                        variant: 'warning',
                        solid: true
                    })
                    return false
                } else {
                    return true
                }
            },
            // validateClicks() {
            //     let sum = 0
            //     for ( let i = 0; i < this.domains.length; i++ ) {
            //          sum += Number(this.domains[i].NumberOfClicks)
            //     }
            //
            //     let balance = Number(this.plans.PlanListing.NumberOfClicks) - sum
            //
            //     if ( sum < Number(this.plans.PlanListing.NumberOfClicks) ) {
            //         this.$bvToast.toast(`You went below the allotted Number of Clicks for this plan. Please select at least ${balance} more across your domains.`, {
            //             title: <strong>Warning: Amount of Clicks</strong>,
            //             variant: 'warning',
            //             solid: true
            //         })
            //         return false
            //     } else {
            //         return true
            //     }
            // },
            loadPlans: function() {
                var request = {'apikey': this.$root.apikey};

                return axios.get(`${this.$root.serverUrl}/admin/clients/product/${this.$route.query.plan_id}`, {params:request}).then(function(resp){
                    //Store the stats
                    this.plans = (resp.data && resp.data.data)? resp.data.data : {};
                }.bind(this));
            },
            incrementDomain() {
                const total = (this.plans.PlanListing) ? this.plans.PlanListing.NumberOfDomains : 0
                if ( this.domains.length !== Number(total) ) {
                    let object = Object.assign({}, {
                        NumberOfClicks: this.plans.PlanListing.Driven === 'click' ? this.plans.PlanListing.NumberOfClicks : 0,
                        domain_id: '',
                        StartTime: '',
                        StopTime: '',
                        StartDate: '',
                        StopDate: '',
                        geos: [],
                        googleanalytics:{
                            source:' ',
                            unqiue: 'Adretreaver',
                            isrequired: false
                        }
                    });
                    object.plan_id =  this.plans.PlanId;
                    object.client_id =  this.plans.ClientId;
                    object.product_id =  this.$route.query.plan_id;

                    this.domains.push(object)
                }
                if( this.domains.length === Number(total) ) {
                    this.$bvToast.toast('You have reached the Maximum amount of allotted domains.', {
                        title: <strong>Information: Maximum domains</strong>,
                        toaster: 'b-toaster-top-center',
                        variant: 'info',
                        solid: true
                    })
                }
            },
            deleteDomain: function(idx) {
                this.domains.splice(idx, 1);
            },
            resetLocation() {
                this.locate.country = ''
                this.locate.state = ''
                this.locate.city = ''
            },
            loadcountries: function(){
                var request = {'apikey': this.$root.apikey, sSearch:''};

                return axios.get(`${this.$root.serverUrl}/admin/countries`, {params:request}).then(function(resp){
                    //Store the stats
                    this.countries = (resp.data && resp.data.data)? resp.data.data : [];
                }.bind(this));
            },
            Getstates: function(){
                this.states = [];
                var request = {'apikey': this.$root.apikey,filters:{}};

                if(this.locate.country) request.filters.country_iso_code = this.locate.country;

                return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                    //Store the stats
                    this.states = (resp.data && resp.data.data)? resp.data.data : [];
                }.bind(this));
            },
            Getcities: function(){
                this.cities = [];
                var request = {'apikey': this.$root.apikey,filters:{}};

                if(this.locate.country) request.filters.country_iso_code = this.locate.country;
                if(this.locate.state) request.filters.subdivision_1_iso_code = this.locate.state;

                return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                    //Store the stats
                    this.cities = (resp.data)? resp.data.data : [];
                }.bind(this));
            },
            getDomain() {
                let request = {'apikey': this.$root.apikey};

                return axios.get(`${this.$root.serverUrl}/admin/domain/product/${this.$route.query.plan_id}`, {params:request}).then((resp) => {
                    this.domains = resp.data.data || [];
                    for(var i = 0; i < this.domains.length;i++){
                        if(this.domains[i].googleanalytics === undefined || this.domains[i].googleanalytics === null){
                            this.domains[i].googleanalytics = {
                                source:' ',
                                unqiue: 'Adretreaver',
                                isrequired: false
                            }
                        }
                    }
                })
            },
            validateAll(index) {
                if ( !this.domains[index].domain_id || !this.domains[index].StartTime || !this.domains[index].StopTime || !this.domains[index].StartDate || !this.domains[index].StopDate ) {
                    return false
                } else { return true }
            },
            validateCorrectness(index) {
                if ( !this.validateTimeHrMinStart(this.domains[index].StartTime) || !this.validateTimeHrMinStop(this.domains[index].StopTime) || !this.validateDate(this.domains[index].StartDate) || !this.validateDate(this.domains[index].StopDate) ) {
                    return false
                } else { return true }
            },
            tosNotify() {
                this.$bvToast.toast('TOS updated successfully', {
                    title: <strong>Information: TOS Updated</strong>,
                    variant: 'info',
                    solid: true
                })
                this.getDomain()
            },
            generateCampaign(dom) {
                /*if ( dom && dom.status === 'verified' ) {
                    axios.put(`${this.$root.serverUrl}/admin/domain/campaigns/${dom._id}`).then((resp) => {
                        if ( resp.data && resp.data.error ) {
                            this.$bvToast.toast(resp.data.error.message, {
                                title: <strong>Warning: Campaign not generated.</strong>,
                                variant: 'danger',
                                solid: true
                            })
                        } else {
                            swal({ title: "Campaign has been generated.", text: "Campaign Generated.", icon:"success" } ).then(() => { location.reload() })
                        }
                    })
                } else {
                    this.$bvToast.toast('Domain not verified', {
                        title: <strong>Warning: Domain not verified.</strong>,
                        variant: 'warning',
                        solid: true
                    })
                }*/
                axios.put(`${this.$root.serverUrl}/admin/domain/campaigns/${dom._id}`).then((resp) => {
                    if ( resp.data && resp.data.error ) {
                        this.$bvToast.toast(resp.data.error.message, {
                            title: <strong>Warning: Campaign not generated.</strong>,
                            variant: 'danger',
                            solid: true
                        })
                    } else {
                        swal({ title: "Campaign has been generated.", text: "Campaign Generated.", icon:"success" } ).then(() => { location.reload() })
                    }
                })
            },
            saveDomainConfig(idx) {
                if ( this.validateCorrectness(idx) && this.validateAll(idx) ) {
                    axios.put(`${this.$root.serverUrl}/admin/domain`, { params: { domains: this.domains[idx] } }).then((resp) => {
                        if ( resp.data && resp.data.error ) {
                            swal({ title: 'Error', text: resp.data.error.message, icon: 'error' })
                        } else {
                            swal({ title: 'Success', text: 'Domain configured successfully.', icon: 'success' }).then(() => {
                                location.reload()
                            })
                        }
                    })
                } else {
                    this.$bvToast.toast('Please check form for discrepancies.', {
                        title: <strong>Error: Form has errors.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                }
            },
            updateDomainConfig(index) {
                if ( this.validateCorrectness(index) && this.validateAll(index) ) {
                    axios.post(`${this.$root.serverUrl}/admin/domain`, { params: { domains: this.domains[index] } } ).then((resp) => {
                        console.log(resp)
                        if ( resp.data && resp.data.error ) {
                            swal({ title: 'Error', text: resp.data.error.message, icon: 'error' })
                        } else {
                            swal({ title: 'Success', text: 'Domain configured successfully.', icon: 'success' }).then(() => {
                                location.reload()
                            })
                        }
                    })
                } else {
                    this.$bvToast.toast('Please check form for discrepancies.', {
                        title: <strong>Error: Form has errors.</strong>,
                        variant: 'danger',
                        solid: true
                    })
                }
            }
        },
        directives: {
            focus: {
                // directive definition
                inserted: function (el) {
                    el.focus()
                }
            }
        }
    }
</script>

<style scoped>
    .editInput {
        color: red;
        font-weight: bolder;
        height: 75px;
        background-color: transparent;
        border: none;
        font-size: 25px;
        text-align: center;
    }
    .dateEditInput {
        color: red;
        font-weight: bolder;
        height: 75px;
        background-color: transparent;
        border: none;
        font-size: 25px;
        text-align: center;
        width: 200px;
    }
    .editInput::placeholder {
        color: red;
        font-weight: bolder;
        height: 75px;
        background-color: transparent;
        border: none;
        font-size: 25px;
        text-align: center;
    }
    input:focus {
        outline: none !important;
    }
    .btm {
        position:absolute;
        bottom:0;
        left:0;
    }

    .hr-header {
        width: 100%;
        line-height: 1em;
        position: relative;
        outline: 0;
        border: 0;
        color: black;
        text-align: center;
        height: 1.5em;
        opacity: .5;
    }

    .hr-header:before {
         content: '';
         background: linear-gradient(to right, transparent, #818078, transparent);
         position: absolute;
         left: 0;
         top: 50%;
         width: 100%;
         height: 1px;
     }

    .hr-header:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        color: black;
        font-weight: bold;
        font-size: 15px;
        padding: 0 .5em;
        line-height: 1.5em;
        color: #000000;
        background-color: #fcfcfa;
     }
</style>
